import React, { useState, useEffect } from "react";
import { getIndustriesSectionData } from "../actions";
import { useQuery } from "@tanstack/react-query";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

const IndustriesSection = ({ homepageIsLoading }) => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery({
    queryKey: ["industrySectionData"],
    queryFn: getIndustriesSectionData,
  });
  const industrySectionData = data?.data?.allIndustryLists;

  const [activeIndustry, setActiveIndustry] = useState([]);

  useEffect(() => {
    setActiveIndustry(industrySectionData && industrySectionData[0]);
  }, [industrySectionData]);

  const getImagePath = (industry) => {
    const industryData = industrySectionData?.find(
      (item) => item?.id === industry?.id
    );
    return industryData?.image?.url;
  };

  const handleIndustryHover = (industry) => {
    setActiveIndustry(industry);
  };

  const handleIndustryClick = (industry) => {
    navigate(`/industries/${industry?.slug}`);
  };

  if (isLoading || homepageIsLoading)
    return (
      <div className="min-h-[700px] w-screen !bg-[#F5F5F0] flex justify-center items-center bg-white">
        <Loader size="100px" />
      </div>
    );

  return (
    <section className={`min-h-[700px] w-full bg-[#F5F5F0] py-24`}>
      <div className="section-container">
        <div className="min-h-[450px] flex flex-col md:flex-row justify-between items-center gap-12">
          <div className="min-h-[325px] w-full md:w-1/2">
            <h2 className="md:text-5xl text-3xl font-bold text-black mb-6">
              Streamline your
              <br />
              wholesale business.
            </h2>
            <p className="text-gray-600 mb-12 max-w-lg">
              We help modernize the way you sell and manage your wholesale and
              commodity products online while making it easier and more
              transparent for your customers to place orders.
            </p>
            <div className="flex flex-wrap gap-3">
              {industrySectionData?.map((industry) => {
                return (
                  <button
                    key={industry?.id}
                    onMouseEnter={() => handleIndustryHover(industry)}
                    onClick={() => handleIndustryClick(industry)}
                    className={`px-4 py-2 rounded-full text-sm transition-colors
                    ${
                      industry.title === activeIndustry?.title
                        ? "bg-black text-white"
                        : "bg-white text-gray-500 hover:bg-gray-50"
                    }`}
                  >
                    {industry.title}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="min-h-[400px] w-full md:w-1/2 transition-opacity duration-300 overflow-hidden">
            <img
              src={getImagePath(activeIndustry)}
              alt={`${activeIndustry?.title} industry`}
              className="object-cover w-full rounded-2xl shadow-lg h-[400px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustriesSection;
