import React, { useState, Suspense } from "react";
import Loader from "./Loader";
import Icon from "./Icon";

const HeroSection = ({ heroSectionData, homepageIsLoading }) => {
  if (homepageIsLoading)
    return (
      <div className="min-h-[1785px] !bg-[#1a1a1a] w-screen flex justify-center items-center bg-white">
        <Loader size="100px" />
      </div>
    );
  return (
    <div className={`min-h-[1785px] bg-[#1a1a1a] text-white py-24`}>
      <div className="absolute inset-0 flex place-content-center items-center overflow-hidden top-0">
        <div className="gradient"></div>
      </div>
      <div className="min-h-[50vh] text-center section-container relative">
        {heroSectionData?.heroSectionHeading && (
          <h1 className="text-3xl md:text-5xl lg:text-7xl max-w-5xl mx-auto font-bold mb-8 !leading-tight whitespace-normal">
            <span className="bg-[#FF7F50] px-6 py-2 rounded-lg mr-2 inline-block">
              {heroSectionData?.heroSectionHeading?.split(" ")[0]}
            </span>
            {heroSectionData.heroSectionHeading.split(" ").slice(1).join(" ")}
          </h1>
        )}
        {heroSectionData?.heroSectionSubheading && (
          <p className="text-xl text-gray-300 mb-16 max-w-3xl mx-auto">
            {heroSectionData?.heroSectionSubheading}
          </p>
        )}

        {heroSectionData &&
          heroSectionData.heroSectionButtonText &&
          heroSectionData.heroSectionButtonLink && (
            <a
              href={heroSectionData.heroSectionButtonLink}
              target={heroSectionData.heroSectionNewWindow ? "_blank" : "_self"}
              className="bg-[#E5FF44] text-black px-9 py-4 rounded-lg text-lg font-medium hover:bg-[#d8ff1a] inline-block"
            >
              {heroSectionData.heroSectionButtonText}
            </a>
          )}
        {(heroSectionData?.heroSectionFeaturedMedia?.video ||
          heroSectionData?.heroSectionFeaturedMedia?.url) && (
          <div className="mt-16 w-full max-w-[1250px] mx-auto px-6 pb-6 relative">
            <div className="absolute inset-0 top-[1.5rem] rounded-lg left-0 bg-white" />
            <div className="w-full relative">
              {heroSectionData?.heroSectionFeaturedMedia?.video && (
                <video
                  src={heroSectionData.heroSectionFeaturedMedia.video.mp4Url}
                  alt={heroSectionData.heroSectionFeaturedMedia.video.alt}
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="w-full rounded-lg object-contain border-[1px] border-[#d3d3d3]"
                />
              )}
              {heroSectionData?.heroSectionFeaturedMedia?.url &&
                !heroSectionData?.heroSectionFeaturedMedia?.video && (
                  <img
                    src={heroSectionData.heroSectionFeaturedMedia.url}
                    alt={heroSectionData.heroSectionFeaturedMedia.alt}
                    className="w-full rounded-lg object-contain"
                  />
                )}
            </div>
          </div>
        )}
      </div>
      <div className="min-h-[250px] section-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 pb-16">
        {heroSectionData?.heroSectionFeatures?.map((feature) => {
          return (
            <div key={feature.id} className="text-center">
              <div className="bg-[#E5FF44] w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-6">
                <Icon className="text-black w-6 h-6" iconName={feature.icon} />
                {/* <DollarSign className="text-black w-6 h-6" /> */}
              </div>
              <h3 className="text-xl font-bold mb-4">{feature.heading}</h3>
              <p className="text-gray-400 leading-relaxed">
                {feature.subheading}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeroSection;
