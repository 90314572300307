import "./App.css";
import "./index.css";
import MarketplaceLanding from "./components/HeroSection";
import TransformSection from "./components/FeaturesSection";
import IndustriesSection from "./components/IndustriesSection";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getHomepageData } from "./actions";
import IndustryPage from "./pages/IndustryPage";
import IndustryListingPage from "./pages/IndustryListingPage";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          {/* <Route path="/industries" element={<IndustryListingPage />} /> */}
          <Route path="/industries/:slug" element={<IndustryPage />} />
        </Routes>
        <Footer />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
