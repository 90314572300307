import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getIndustryPageData } from "../actions";
import { useParams } from "react-router-dom";
import { StructuredText } from "react-datocms";
import Loader from "../components/Loader";
import GetStarted from "../components/GetStarted";
import "../styles/industry-page.css";

const IndustryPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { slug } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["industryPageData", slug],
    queryFn: () => getIndustryPageData(slug),
  });
  const industryData = data?.data?.allIndustryLists[0];

  if (isLoading)
    return (
      <div className="min-h-[1700px] w-screen !bg-[#1a1a1a] flex justify-center items-center bg-white">
        <Loader size="100px" />
      </div>
    );

  return (
    <div className="min-h-screen">
      <div className={`min-h-[600px]] bg-[#1a1a1a] text-white py-24`}>
        <div className="h-full section-container !py-0 text-center">
          <h2 className="uppercase text-sm">Industries</h2>
          <h1 className="text-3xl lg:text-5xl max-w-5xl mx-auto font-bold mb-8 !leading-tight whitespace-normal mt-6">
            {industryData?.industryPageHeading &&
            industryData?.industryPageHeading.length > 0
              ? industryData?.industryPageHeading
              : `Bring your wholesale ${industryData?.title} business online`}
          </h1>
          <h3 className="text-xl text-gray-300 max-w-3xl mx-auto mt-16">
            {industryData?.industryPageSubheading &&
            industryData?.industryPageSubheading.length > 0
              ? industryData?.industryPageSubheading
              : `            Trading Systems is focused on modernizing the antiquated, day-to-day
            sales and trading operations of traditional wholesalers. We believe
            in helping wholesale B2B companies to scale their ${industryData?.title} business with our
            streamlined, easy-to-use platform.`}
          </h3>
          <a
            href={"#"}
            // target={feature.newTab ? "_blank" : "_self"}
            target="_self"
            className="bg-[#E5FF44] text-black px-6 py-2 rounded-lg text-sm font-medium hover:bg-[#d8ff1a] max-w-fit inline-block mt-6"
          >
            {industryData?.buttonText && industryData?.buttonText.length > 0
              ? industryData?.buttonText
              : "Get Started"}
          </a>
        </div>
      </div>
      <div className="min-h-[1100px] bg-white py-24">
        <div className="section-container">
          <StructuredText data={industryData?.industryPageContent?.value} />
        </div>
      </div>
      <GetStarted />
    </div>
  );
};

export default IndustryPage;
