export async function getHomepageData() {
  try {
    const data = await fetch("https://graphql.datocms.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
      },
      body: JSON.stringify({
        query: `
          {
            homepage {
              heroSectionHeading
              heroSectionSubheading
              heroSectionButtonText
              heroSectionButtonLink
              heroSectionNewWindow
              heroSectionFeaturedMedia {
                video {
                  mp4Url
                  alt
                }
                url
                width
                height
                alt
              }
              heroSectionFeatures {
                id
                heading
                subheading
                icon
              }
              featuresSectionHeading
              featuresSectionSubheading
              features {
                id
                heading
                subheading
                buttonText
                buttonUrl
                newTab
                image {
                  width
                  height
                  url
                  alt
                }
              }
            }
          }
        `,
      }),
    });
    return data.json();
  } catch (error) {
    console.log(error);
  }
}
// export async function getHeroSectionData() {
//   try {
//     const data = await fetch("https://graphql.datocms.com/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
//       },
//       body: JSON.stringify({
//         query: `
//           {
//             homepage {
//               heroSectionHeading
//               heroSectionSubheading
//               heroSectionButtonText
//               heroSectionButtonLink
//               heroSectionNewWindow
//               heroSectionFeaturedMedia {
//                 video {
//                   mp4Url
//                   alt
//                 }
//                 url
//                 width
//                 height
//                 alt
//               }
//               heroSectionFeatures {
//                 id
//                 heading
//                 subheading
//                 icon
//               }
//             }
//           }
//         `,
//       }),
//     });
//     return data.json();
//   } catch (error) {
//     console.log(error);
//   }
// }
// export async function getFeaturesSectionData() {
//   try {
//     const data = await fetch("https://graphql.datocms.com/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
//       },
//       body: JSON.stringify({
//         query: `
//           {
//             homepage {
//               featuresSectionHeading
//               featuresSectionSubheading
//               features {
//                 id
//                 heading
//                 subheading
//                 buttonText
//                 buttonUrl
//                 newTab
//                 image {
//                   width
//                   height
//                   url
//                   alt
//                 }
//               }
//             }
//           }
//         `,
//       }),
//     });
//     return data.json();
//   } catch (error) {
//     console.log(error);
//   }
// }

export async function getIndustriesSectionData() {
  try {
    const data = await fetch("https://graphql.datocms.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
      },
      body: JSON.stringify({
        query: `
          {
            allIndustryLists {
              id
              title
              slug
              image {
                url
                alt
                height
                width
              }
            }
          }
        `,
      }),
    });
    return data.json();
  } catch (error) {
    console.log(error);
  }
}

export async function getGetStartedSectionData() {
  try {
    const data = await fetch("https://graphql.datocms.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
      },
      body: JSON.stringify({
        query: `
          {
            getStartedSection {
              heading
              buttonText
            }
          }
        `,
      }),
    });
    return data.json();
  } catch (error) {
    console.log(error);
  }
}

export async function getIndustryPageData(industrySlug) {
  console.log("INDUSTRY SLUG", industrySlug);
  try {
    const data = await fetch("https://graphql.datocms.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
      },
      body: JSON.stringify({
        query: `
          {
            allIndustryLists(filter: {slug: {eq: "${industrySlug}"}}) {
              id
              title
              image {
                url
                height
                width
                alt
              }
              industryPageHeading
              industryPageSubheading
              buttonText
              industryPageContent {
                value
              }
            }
          }
        `,
      }),
    });
    return data.json();
  } catch (error) {
    console.log(error);
  }
}
