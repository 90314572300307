import React from "react";
import Loader from "./Loader";
import { useQuery } from "@tanstack/react-query";
import { getGetStartedSectionData } from "../actions";

const GetStarted = ({ homepageIsLoading }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["getStartedSectionData"],
    queryFn: getGetStartedSectionData,
  });

  const getStartedSectionData = data?.data?.getStartedSection;

  const renderHeadingWithLineBreak = (heading) => {
    if (!heading) return null;
    const words = heading.split(" ");
    const halfwayIndex = Math.floor(words.length / 2);
    const firstPart = words.slice(0, halfwayIndex).join(" ");
    const secondPart = words.slice(halfwayIndex).join(" ");
    return (
      <>
        {firstPart}
        <br />
        {secondPart}
      </>
    );
  };

  if (isLoading || homepageIsLoading)
    return (
      <div className="min-h-[425px] w-screen !bg-[#1a1a1a] flex justify-center items-center bg-white">
        <Loader size="100px" />
      </div>
    );
  return (
    <div className="min-h-[425px] bg-[#1a1a1a] bg-primary py-24">
      <div className="section-container">
        <h2 className="text-3xl md:text-5xl font-bold mb-8 text-white">
          {renderHeadingWithLineBreak(getStartedSectionData?.heading)}
        </h2>
        <button className="bg-[#E5FF44] text-black px-6 py-3 rounded-lg text-sm font-medium hover:bg-[#d8ff1a]">
          {getStartedSectionData?.buttonText}
        </button>
      </div>
    </div>
  );
};

export default GetStarted;
