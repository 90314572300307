import React from "react";

const Loader = ({ size = "48px" }) => {
  const loaderStyle = {
    width: size,
    height: size,
  };

  return (
    <div
      className="relative rounded-full animate-loaderRotate"
      style={loaderStyle}
    >
      <div className="absolute inset-0 rounded-full border-4 border-white animate-loaderPrixClipFix"></div>
      <div className="absolute inset-1.5 rounded-full border-4 border-[#FF3D00] animate-loaderPrixClipFixReverse"></div>
    </div>
  );
};

export default Loader;
