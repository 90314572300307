import React from "react";

const Footer = () => {
  const footerSections = {
    Customers: ["Wholesalers", "Distributors", "Consumers"],
    Company: ["Contact Us", "LinkedIn", "Press"],
    Products: ["Exchange", "Marketplace", "CRM", "Mobile Application"],
    Solutions: [
      "Automated Fulfillment",
      "Live Tracking",
      "Trucking App",
      "Invoicing",
      "Lead Generation",
      "Dynamic Storefront",
      "AI Integration",
      "Integrated SEO",
    ],
    Industries: [
      "Lumber",
      "Plastics & Rubber",
      "Construction",
      "Raw Materials",
      "Chemicals",
      "Energy",
      "Automotive Parts",
      "Scrap Metals",
      "Manufacturing",
      "Forest Products",
      "HVAC",
      "Textiles",
      "Retail",
      "Agricultural Products",
      "Hardware",
      "& More",
    ],
  };

  return (
    <footer className="bg-[#1a1a1a] text-white">
      <div className="section-container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 mb-24">
          {Object.entries(footerSections).map(([title, items]) => (
            <div
              className="border-t-2 border-white border-dotted pt-4"
              key={title}
            >
              <h3 className="font-medium mb-6">{title}</h3>
              <ul className="space-y-3">
                {items.map((item) => (
                  <li key={item}>
                    <a
                      href="#"
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Bar */}
        <div className="flex flex-col-reverse md:flex-row  gap-6 md:gap-[unset] justify-between items-start text-sm text-gray-400">
          <div className="">Copyright © Trading Systems 2025</div>
          <div className="flex flex-col md:flex-row gap-6">
            <a href="#" className="hover:text-white transition-colors">
              Terms of Service
            </a>
            <a href="#" className="hover:text-white transition-colors">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
