import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
  navigationMenuTriggerStyle,
} from "../components/ui/navigation-menu";
import Icon from "../components/Icon";
import { useQuery } from "@tanstack/react-query";
import { getIndustriesSectionData } from "../actions";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isIndustriesOpen, setIsIndustriesOpen] = useState(false);

  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery({
    queryKey: ["industrySectionData"],
    queryFn: getIndustriesSectionData,
  });
  const industries = data?.data?.allIndustryLists;

  console.log(industries);

  return (
    <div className="w-screen flex justify-between items-center px-8 py-6 relative bg-transparent z-10">
      <div className="text-white px-8 py-2 rounded-lg font-bold text-3xl">
        <a href="/">Trading Systems</a>
      </div>
      <NavigationMenu className="hidden mdLg:flex">
        <NavigationMenuList>
          {/* CUSTOMERS */}
          <NavigationMenuItem>
            <NavigationMenuTrigger>Customers</NavigationMenuTrigger>
            <NavigationMenuContent className="p-6 bg-[#1a1a1a]">
              <ul className="md:w-fit lg:w-fit">
                <li className="">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100"
                      href="/buyers"
                    >
                      Wholesalers
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100"
                      href="/buyers"
                    >
                      Distributors
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100"
                      href="/buyers"
                    >
                      Consumers
                    </a>
                  </NavigationMenuLink>
                </li>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          {/* COMPANY */}
          <NavigationMenuItem>
            <NavigationMenuTrigger>Company</NavigationMenuTrigger>
            <NavigationMenuContent className="p-6 bg-[#1a1a1a]">
              <ul className="w-fit">
                <li className="">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100"
                      href="/buyers"
                    >
                      Contact Us
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100"
                      href="/buyers"
                    >
                      About Us
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100"
                      href="/buyers"
                    >
                      Meet the Team
                    </a>
                  </NavigationMenuLink>
                </li>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          {/* PRODUCTS */}
          <NavigationMenuItem>
            <NavigationMenuTrigger>Products</NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className="grid gap-3 p-4 md:grid-cols-2 md:w-[400px]">
                <li
                  title={"Exchange"}
                  href={"/products"}
                  className="p-2 flex items-center"
                >
                  <Icon iconName="hand-coins" color="white" className="mr-2" />
                  <p className="text-white font-semibold m-0 whitespace-nowrap">
                    Exchange
                  </p>
                </li>
                <li
                  title={"Marketplace"}
                  href={"/products"}
                  className="p-2 flex items-center"
                >
                  <Icon iconName="store" color="white" className="mr-2" />
                  <p className="text-white font-semibold m-0 whitespace-nowrap">
                    Marketplace
                  </p>
                </li>
                <li
                  title={"CRM"}
                  href={"/products"}
                  className="p-2 flex items-center"
                >
                  <Icon
                    iconName="book-open-check"
                    color="white"
                    className="mr-2"
                  />
                  <p className="text-white font-semibold m-0 whitespace-nowrap">
                    CRM
                  </p>
                </li>
                <li
                  title={"Mobile App"}
                  href={"/products"}
                  className="p-2 flex items-center"
                >
                  <Icon iconName="smartphone" color="white" className="mr-2" />
                  <p className="text-white font-semibold m-0 whitespace-nowrap">
                    Mobile App
                  </p>
                </li>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          {/* SOLUTIONS */}
          <NavigationMenuItem>
            <NavigationMenuTrigger>Solutions</NavigationMenuTrigger>
            <NavigationMenuContent className="p-6 bg-[#1a1a1a]">
              <ul className="md:w-fit lg:w-fit">
                <li className="">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      Automated Fulfillment
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      Live Tracking
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      Trucking App
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      Invoicing
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      Lead Generation
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      Dynamic Storefront
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      AI Integration
                    </a>
                  </NavigationMenuLink>
                </li>
                <li className="mt-3">
                  <NavigationMenuLink asChild>
                    <a
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                      href="/buyers"
                    >
                      Integrated SEO
                    </a>
                  </NavigationMenuLink>
                </li>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          {/* INDUSTRIES */}
          <NavigationMenuItem>
            <NavigationMenuTrigger>Industries</NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className="grid gap-3 p-6 md:grid-cols-2 md:w-[400px]">
                {industries?.map((industry) => {
                  return (
                    <li
                      key={industry?.id}
                      title={industry?.title}
                      className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                    >
                      <a
                        className="text-white hover:text-gray-400 transition-all duration-100 whitespace-nowrap"
                        href={`/industries/${industry?.slug}`}
                      >
                        {industry?.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <a href="/#" legacyBehavior passHref>
              <NavigationMenuLink
                className={
                  (navigationMenuTriggerStyle(),
                  "bg-[#E5FF44] text-black px-6 py-2 rounded-lg text-sm font-medium hover:bg-[#d8ff1a] max-w-fit inline-block")
                }
              >
                Get Started
              </NavigationMenuLink>
            </a>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
      <Sheet>
        <SheetTrigger
          className="mdLg:hidden bg-[#1a1a1a] text-white border-none"
          asChild
        >
          <Button variant="outline">Menu</Button>
        </SheetTrigger>
        <SheetContent className="w-[80%] h-screen overflow-scroll !max-w-[unset]">
          <SheetHeader className="text-left mb-5">
            <SheetTitle className="text-2xl">
              <a href="/">Trading Systems</a>
            </SheetTitle>
          </SheetHeader>
          <div className="w-full mb-5">
            <SheetTitle className="mb-2">Customers</SheetTitle>
            <ul className="">
              <li className="ml-4 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Wholesalers
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Distributors
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Consumers
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
            </ul>
          </div>
          <div className="w-full mb-5">
            <SheetTitle className="mb-2">Company</SheetTitle>
            <ul className="">
              <li className="ml-4 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Contact Us
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  About Us
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Meet the Team
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
            </ul>
          </div>
          <div className="w-full mb-5">
            <SheetTitle className="mb-2">Products</SheetTitle>
            <ul className="">
              <li className="ml-4 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Exchange
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Marketplace
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  CRM
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Mobile App
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
            </ul>
          </div>
          <div className="w-full mb-5">
            <SheetTitle className="mb-2">Solutions</SheetTitle>
            <ul className="">
              <li className="ml-4 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Automated Fulfillment
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Live Tracking
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Trucking App
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Invoicing
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Lead Generation
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Dynamic Storefront
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  AI Integration
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
              <li className="ml-4 mt-2 flex items-center justify-between">
                <a
                  className="text-black hover:text-gray-400 transition-all duration-100"
                  href="/buyers"
                >
                  Integrated SEO
                </a>
                <Icon iconName="chevron-right" color="black" />
              </li>
            </ul>
          </div>
          <div className="w-full mb-5">
            <SheetTitle className="mb-2">Industries</SheetTitle>
            <ul className="">
              {industries?.slice(0, 5).map((industry) => {
                return (
                  <li className="ml-4 flex items-center justify-between mb-2">
                    <a
                      className="text-black hover:text-gray-400 transition-all duration-100"
                      href={`/industries/${industry?.slug}`}
                    >
                      {industry?.title}
                    </a>
                    <Icon iconName="chevron-right" color="black" />
                  </li>
                );
              })}
              {industries?.length > 5 && (
                <li className="ml-4 flex items-center justify-between mb-2">
                  <a
                    className="text-black hover:text-gray-400 transition-all duration-100 underline"
                    href={"/industries"}
                  >
                    See More Industries
                  </a>
                  <Icon iconName="chevron-right" color="black" />
                </li>
              )}
            </ul>
          </div>
          <SheetFooter>
            <SheetClose className="" asChild>
              <Button className="bg-[#E5FF44] text-black px-6 py-2 rounded-lg text-sm font-medium hover:bg-[#d8ff1a] inline-block w-full mt-6">
                Get Started
              </Button>
            </SheetClose>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default Header;
