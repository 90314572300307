import * as lucideIcons from "lucide-react";

const toPascalCase = (str) =>
  str
    .toLowerCase() // Ensure all letters are lowercase
    .replace(/(^\w|-\w)/g, (match) => match.replace("-", "").toUpperCase());

const Icon = ({ iconName, className, color }) => {
  const pascalCaseName = toPascalCase(iconName);
  const LucideIcon = lucideIcons[pascalCaseName]; // Dynamically find the component

  return LucideIcon ? (
    <LucideIcon className={className} color={color} />
  ) : (
    <span>Icon not found</span>
  );
};

export default Icon;
