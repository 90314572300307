import React from "react";
import MarketplaceLanding from "../components/HeroSection";
import FeaturesSection from "../components/FeaturesSection";
import GetStarted from "../components/GetStarted";
import IndustriesSection from "../components/IndustriesSection";
import HeroSection from "../components/HeroSection";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { getHomepageData } from "../actions";

const Homepage = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["homepageData"],
    queryFn: getHomepageData,
  });
  return (
    <div>
      <HeroSection
        homepageIsLoading={isLoading}
        heroSectionData={{
          heroSectionHeading: data?.data?.homepage?.heroSectionHeading,
          heroSectionSubheading: data?.data?.homepage?.heroSectionSubheading,
          heroSectionFeaturedMedia:
            data?.data?.homepage?.heroSectionFeaturedMedia,
          heroSectionFeatures: data?.data?.homepage?.heroSectionFeatures,
        }}
      />
      <FeaturesSection
        homepageIsLoading={isLoading}
        featuresSectionData={{
          featuresSectionHeading: data?.data?.homepage?.featuresSectionHeading,
          featuresSectionSubheading:
            data?.data?.homepage?.featuresSectionSubheading,
          features: data?.data?.homepage?.features,
        }}
      />
      <IndustriesSection homepageIsLoading={isLoading} />
      <GetStarted homepageIsLoading={isLoading} />
    </div>
  );
};

export default Homepage;
