import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getFeaturesSectionData } from "../actions";
import Loader from "./Loader";
const FeaturesSection = ({ homepageIsLoading, featuresSectionData }) => {
  if (homepageIsLoading)
    return (
      <div className="min-h-[2300px] w-screen bg-[#ffffff] flex justify-center items-center bg-white">
        <Loader size="100px" />
      </div>
    );
  return (
    <section className={`min-h-[2275px] w-full py-24 bg-[#FFFFFF]`}>
      {/* Transform Section */}
      <div className="min-h-[375px] section-container text-center">
        <h2 className=" md:max-w-5xl mx-auto md:text-5xl text-3xl font-bold mb-8 leading-tight">
          {featuresSectionData?.featuresSectionHeading}
        </h2>
        <p className="text-gray-400 w-full md:max-w-3xl mx-auto mb-24 text-lg">
          {featuresSectionData?.featuresSectionSubheading}
        </p>
      </div>

      <div className="section-container min-h-[1650px] pb-24">
        {featuresSectionData?.features?.map((feature, index) => {
          const isEven = index % 2 === 0;
          if (index === 1) {
            console.log(isEven);
          }
          return (
            <div
              key={feature.id}
              className={`bg-white rounded-[32px] overflow-hidden flex flex-col ${
                index !== featuresSectionData?.features?.length - 1
                  ? "mb-24"
                  : ""
              } ${
                isEven || index === 0 ? "md:flex-row" : "md:flex-row-reverse"
              } border border-gray-100 shadow-lg min-h-[480px] max-w-[1440px] mx-auto`}
            >
              <div className="w-full md:min-h-[unset] md:w-1/2 p-14 text-center flex flex-col justify-center items-center">
                <h3 className="md:text-4xl text-2xl font-bold text-black mb-4">
                  {feature.heading}
                </h3>
                <p className="text-gray-600 mb-8 leading-relaxed">
                  {feature.subheading}
                </p>
                <a
                  href={feature.buttonUrl}
                  target={feature.newTab ? "_blank" : "_self"}
                  className="bg-[#E5FF44] text-black px-6 py-2 rounded-lg text-sm font-medium hover:bg-[#d8ff1a] max-w-fit inline-block"
                >
                  {feature.buttonText}
                </a>
              </div>
              <div className="bg-[#0A0A29] w-full relative overflow-hidden flex justify-center items-center md:block justify-[unset] items-[unset] md:w-1/2 md:min-h-[unset] 2xl:flex 2xl:justify-center 2xl:items-center">
                <img
                  src={feature.image.url}
                  alt={feature.image.alt}
                  height={feature.image.height}
                  width={feature.image.width}
                  className={`relative p-14 rounded-lg md:h-[400px] md:p-[unset] md:w-auto md:max-w-[unset] md:absolute ${
                    isEven || index === 0 ? "md:left-[60px]" : "md:right-[60px]"
                  }   md:top-[50%] md:translate-y-[-50%] ${
                    isEven || index === 0
                      ? "2xl:left-[unset]"
                      : "2xl:right-[unset]"
                  }  2xl:relative 2xl:top-[unset] 2xl:translate-y-[unset] 2xl:flex 2xl:justify-center 2xl:items-center`}
                />
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="section-container pb-24">
        <div className="bg-white rounded-[32px] overflow-hidden flex flex-col md:flex-row border border-gray-100 shadow-lg min-h-[480px] max-w-[1440px] mx-auto">
          <div className="bg-[#0A0A29] w-full relative overflow-hidden flex justify-center items-center md:block justify-[unset] items-[unset] md:w-1/2 md:min-h-[unset] ">
            <img
              src="invoicing.png"
              alt="Order Management Dashboard"
              className="relative p-14 rounded-lg md:h-[400px] md:p-[unset] md:w-auto md:max-w-[unset] md:absolute md:right-[60px] md:top-[50%] md:translate-y-[-50%]"
            />
          </div>
          <div className="w-full md:min-h-[unset] md:w-1/2 p-14 text-center flex flex-col justify-center items-center">
            <h3 className="md:text-4xl text-2xl font-bold text-black mb-4">
              Easy Billing Options
            </h3>
            <p className="text-gray-600 mb-8 leading-relaxed">
              With up-to-the-minute inventory reports, automated billing and
              invoicing, advanced payment options (like ACH, BNPL, etc.) and
              easy-to-print order forms and receipts, we can make your online
              ordering process seamless and completely transparent.
            </p>
            <button className="bg-[#E5FF44] text-black px-6 py-2 rounded-lg text-sm font-medium hover:bg-[#d8ff1a] max-w-fit">
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className="section-container pb-24">
        <div className="bg-white rounded-[32px] overflow-hidden flex flex-col md:flex-row border border-gray-100 shadow-lg min-h-[480px] max-w-[1440px] mx-auto">
          <div className="w-full md:min-h-[unset] md:w-1/2 p-14 text-center flex flex-col justify-center items-center">
            <h3 className="md:text-4xl text-2xl font-bold text-black mb-4">
              Automated Fulfillment & Order Tracking
            </h3>
            <p className="text-gray-600 mb-8 leading-relaxed">
              Our technology enables a streamlined order - which includes pickup
              and delivery services from our logistics partners. Once an order
              is placed, your platform will automatically handle the logistics
              and you and your customer can watch the delivery progress right
              from the dashboard.
            </p>
            <button className="bg-[#E5FF44] text-black px-6 py-2 rounded-lg text-sm font-medium hover:bg-[#d8ff1a] max-w-fit">
              Learn More
            </button>
          </div>
          <div className="bg-[#0A0A29] w-full relative overflow-hidden flex justify-center items-center md:block justify-[unset] items-[unset] md:w-1/2 md:min-h-[unset] ">
            <img
              src="tracking.png"
              alt="Order Management Dashboard"
              className="relative p-14 rounded-lg md:h-[400px] md:p-[unset] md:w-auto md:max-w-[unset] md:absolute md:left-[60px] md:top-[50%] md:translate-y-[-50%]"
            />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default FeaturesSection;
